
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { VueDraggableNext } from "vue-draggable-next";
import { useRoute } from "vue-router";
import ApiService from "@/core/services/ApiService";
import c from "vue3-quilljs/lib/vue3-quilljs.es";
import { props } from "@syncfusion/ej2-vue-richtexteditor/src/rich-text-editor/richtexteditor.component";

export default {
  name: "ContactsTE",
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    add_disabled: {
      type: Boolean,
      default: false,
    },
    disable: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["createElement", "removeElement", "fieldSelected", "saveElement"],

  setup() {
    const store = useStore();
    const route = useRoute();
    const loading = ref(false);
    const contactTable = computed(() => store.getters.Contacts);

    const headers = ref([
      { label: "iselectexistent", prop: "id" },
      { label: "iname", prop: "name" },
      { label: "ilast_name", prop: "last_name" },
      { label: "contacts_type", prop: "contact_type_id" },
      { label: "identification", prop: "identification" },
      // { label: "iborndate", prop: "born_date" },
    ]);
    const headerTable = ref(headers.value);

    const getData = () => {
      //
    };
    const elements = ref({
      loading: false,
      options: [],
      list: [],
    }) as any;

    const getContactInfo = (data, i) => {
      ApiService.get("/api/contacts/" + data.id).then(function (response) {
        store.commit("updateContact", { pos: i, contact: response.data });
        contactTable.value[i].identification = response.data.identification;
        contactTable.value[i].gender = response.data.gender;
        contactTable.value[i].name = response.data.name;
        contactTable.value[i].last_name = response.data.last_name;
        contactTable.value[i].born_date = response.data.born_date;
        const comunications = [] as any;
        contactTable.value.forEach((x) => {
          x.communications.forEach((y) => {
            comunications.push(y);
          });
        });
        store.commit("setMedias", comunications);
      });
    };

    const contactSelected = ref({
      contact_type_id: 1,
      entities: route.params.id,
    });
    const selectElements = (query) => {
      if (query !== "") {
        elements.value.loading = true;
        setTimeout(() => {
          elements.value.loading = false;
          elements.value.options = elements.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
          if (!elements.value.options.length) {
            store.commit("setLoading", false);
            ApiService.query(`/api/contacts/combo/lists`, {
              params: { per_page: 50, name: query },
            }).then(({ data }) => {
              elements.value.options = data.contacts;
            });
          }
        }, 200);
      }
    };
    onMounted(() => {
      getData();
      store.commit("setLoading", false);
      ApiService.get("/api/contacts/combo/lists").then(function (response) {
        elements.value.options = response.data.contacts;
        contactTable.value.forEach((x) => {
          const val = elements.value.options.find((y) => y.id === x.id);
          if (!val) {
            store.commit("setLoading", false);
            ApiService.get("/api/contacts/" + x.id).then(function (response) {
              elements.value.options.push(response.data);
            });
          }
        });
        store.commit("setLoading", false);
        ApiService.get("/api/contact-types/lists?per_page=1000").then(function (response) {
          elements.value.list = response.data.contact_types;
        });
      });
    });

    return {
      elements,
      contactTable,
      loading,
      headers,
      headerTable,
      contactSelected,
      getContactInfo,
      selectElements,
    };
  },
};
