
import { computed, defineComponent, onMounted, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import ContactsTE from "@/components/entities/tables-editable/ContactsTE.vue";
import CommunicationsTE from "@/components/entities/tables-editable/CommunicationsTE.vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { ElNotification } from "element-plus";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "EntitiesContacts",
  components: {
    ContactsTE,
    CommunicationsTE,
  },
  props: {
    profile: Object,
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const { t } = useI18n();
    const entities_type_list = ref([]) as any;
    const contacts = ref([]) as any;
    const entities_list = ref([]);
    const entity_address_type_warning_flag = ref(true);
    const contactData = computed(() => store.getters.Contacts);
    const communicationData = computed(() => store.getters.Communications);
    const contactId = computed(() => store.getters.ContactSelected);
    const communicationsTable = computed(() => store.getters.Medias);
    const sectorData = computed(() => store.getters.Sectors);
    const addressData = computed(() => store.getters.Addresses);
    const officesData = computed(() => store.getters.Offices);
    const entityData = computed(() => store.getters.EntityData);
    const dataTypes = computed(() => store.getters.EntityTypes);
    const eDataTable = computed(() => store.getters.EconomicData);
    const sync = computed(() => store.getters.Sync);
    const saveAll = () => {
      store.commit("setLoadingStatus", true);
      const entitiesModel = [] as any;
      dataTypes.value.forEach(function (value) {
        entitiesModel.push(value.id);
      });
      const addressModel = [] as any;
      addressData.value.forEach(function (value) {
        if (value.address_types.length > 0) {
          if (value.address_types[value.address_types.length - 1].id) {
            value.address_types = value.address_types.map((x) => x.id);
          }
        } else {
          if (entity_address_type_warning_flag.value) {
            entity_address_type_warning_flag.value = false;
            ElNotification({
              title: t("warning"),
              message: t("entity_address_type_warning"),
              type: "warning",
            });
          }
        }
        addressModel.push(value.id);
      });
      const contactsModel = [] as any;
      contactData.value.forEach(function (value, keyContact) {
        const communication = [] as any;
        communicationsTable.value.forEach(function (valCom) {
          if (valCom.contact_id === value.id) {
            communication.push({
              media_id: valCom.media_id,
              value: valCom.value,
              remark: valCom.remark,
            });
          }
        });
        if (value.id) {
          contactsModel.push({
            born_date: value.born_date,
            contact_type_id: value.contact_type_id,
            gender: value.gender,
            id: value.id,
            identification: value.identification,
            last_name: value.last_name,
            name: value.name,
            communications: communication,
          });
        } else {
          contactsModel.push({
            born_date: value.born_date,
            contact_type_id: value.contact_type_id,
            gender: value.gender,
            identification: value.identification,
            last_name: value.last_name,
            name: value.name,
            communications: communication,
          });
        }
      });
      const officesModel = [] as any;
      officesData.value.forEach(function (value) {
        officesModel.push(value.id);
      });
      const sectorsModel = [] as any;
      sectorData.value.forEach(function (value) {
        sectorsModel.push(value.id);
      });
      const syncModel = [] as any;
      sync.value.forEach(function (value) {
        syncModel.push(value);
      });
      const economicData = [] as any;
      eDataTable.value.forEach(function (value) {
        economicData.push({
          type: value.type,
          office_id: value.office_id,
          payment_days: value.payment_days,
          applies: value.applies,
          payment_type_detail_id: value.payment_type_detail_id,
        });
      });
      const body = {
        name: entityData.value.name,
        legal_name: entityData.value.legal_name,
        identification_type_id: entityData.value.identification_type_id,
        identification: entityData.value.identification,
        email: entityData.value.email,
        entity_types: entitiesModel,
        sectors: sectorsModel,
        offices: officesModel,
        contacts: contactsModel,
        addresses: addressData.value,
        comunications: communicationsTable.value,
        economic_data: economicData,
        synchronize_flags: syncModel,
        communications: communicationData.value,
      };
      let invalidContactTable = false;
      let invalidCommunicationTable = false;
      if (contactData.value.length > 0) {
        invalidContactTable =
          contactData.value[contactData.value.length - 1].id == undefined ||
          contactData.value[contactData.value.length - 1].name == "" ||
          !contactData.value[contactData.value.length - 1].contact_type_id;
      }
      if (communicationsTable.value.length > 0) {
        invalidCommunicationTable =
          !communicationsTable.value[communicationsTable.value.length - 1]
            .contact_id ||
          communicationsTable.value[communicationsTable.value.length - 1]
            .media_id == "" ||
          communicationsTable.value[communicationsTable.value.length - 1]
            .value == "";
      }
      if (!invalidContactTable && !invalidCommunicationTable) {
        ApiService.post(
          "/api/entities/updateAll/" + route.params.id,
          body
        ).then(function (response) {
          store.commit("setLoadingStatus", false);
          store.commit("setContacts", response.data.contacts);
        });
      } else {
        store.commit("setLoadingStatus", false);
        ElNotification({
          title: "Error",
          message: invalidContactTable
            ? t("rconatcttable")
            : invalidCommunicationTable
            ? t("rcommunicationtable")
            : "",
          type: "error",
        });
      }
    };
    const contactDetail = ref({
      gender: "m",
      entity_type: 1,
      b_date: "",
      name: "",
      lastname: "",
      mobile: "",
      phone: "",
      identification_id: 0,
      entity_id: 0,
      email: "",
    }) as any;
    const addContacts = () => {
      contacts.value.push({
        gender: contactDetail.value.gender,
        contact_type_id: contactDetail.value.contact_type_id,
        born_date: contactDetail.value.b_date,
        name: contactDetail.value.name,
        cellphone: contactDetail.value.mobile,
        phone: contactDetail.value.phone,
        identification: contactDetail.value.identification_id,
        last_name: contactDetail.value.lastname,
        entity_id: contactDetail.value.entity_id,
        email: contactDetail.value.email,
      });
    };
    const findContacts = () => {
      contactDetail.value = {
        gender: "m",
        entity_type: 1,
        b_date: "",
        name: "",
        lastname: "",
        mobile: "",
        phone: "",
        identification_id: 0,
        entity_id: 0,
        email: "",
      };
      ApiService.get("/api/type-entities").then(function (response) {
        entities_type_list.value = response.data;
        contactDetail.value.entity_type = response.data[0].id;
      });
    };
    const addAction = ref(false);
    const saveContactCommunication = () => {
      ApiService.get("/api/contacts/" + contactId.value).then(function (
        response
      ) {
        const entities = [] as any;
        response.data.entities.forEach(function (value) {
          entities.push(value.id);
        });
        const request = {
          name: response.data.name,
          last_name: response.data.last_name,
          gender: response.data.gender,
          born_date: response.data.born_date,
          identification: response.data.identification,
          contact_type_id: response.data.contact_type_id,
          entities: entities,
          communications: communicationsTable.value,
        };
        ApiService.put("/api/contacts/" + contactId.value, request).then(
          function () {
            saveEntity();
          }
        );
      });
    };
    const getCommunication = (data) => {
      store.commit("setMedias", data.communications);
      store.commit("setContactSelected", data.id);
      ApiService.get("/api/contacts/" + data.id).then(function (response) {
        response.data.communications.forEach((item, index) => {
          store.commit("setMedias", item);
        });
      });
    };
    const saveContact = (data) => {
      const request = {
        name: data.name,
        last_name: data.last_name,
        gender: data.gender,
        born_date: data.born_date,
        identification: data.identification,
        contact_type_id: 1,
        entities: [route.params.id],
        communications: communicationData.value,
      };
      ApiService.post("/api/contacts", request).then(function (response) {
        //
      });
    };
    const removeCommunication = (i) => {
      communicationsTable.value.splice(i, 1);
    };
    const removeContact = (i) => {
      if (contactData.value.length === 1 && !contactData.value[0].id) {
        store.commit("setContacts", []);
        store.commit("clearMedias");
      } else {
        const item = contactData.value[i];
        contactData.value.splice(i, 1);
        communicationsTable.value.forEach(function (val, key) {
          if (val.keyContact === i) {
            communicationsTable.value.splice(key, 1);
          }
        });
      }
    };
    const createContact = (data) => {
      let invalidTable = false;
      if (contactData.value.length > 0) {
        contactData.value.forEach((item, index) => {
          if (index === contactData.value.length - 1) {
            invalidTable = item.id == undefined;
          }
        });
      }
      if (!invalidTable) {
        store.commit("createContact", {
          contact_type_id: data.contact_type_id,
          entities: data.entities,
        });
      } else {
        store.commit("setLoadingStatus", false);
        ElNotification({
          title: "Error",
          message: t("rconatcttable"),
          type: "error",
        });
      }
    };
    const createCommunication = () => {
      store.commit("createMedias", {
        media_id: "",
        value: "",
        remark: "",
        id: undefined,
      });
    };
    const saveEntity = () => {
      const entitiesModel = [] as any;
      dataTypes.value.forEach(function (value) {
        entitiesModel.push(value.id);
      });
      const addressModel = [] as any;
      addressData.value.forEach(function (value) {
        value.address_types = value.address_types.map((x) => x.id);
        addressModel.push(value.id);
      });
      const contactsModel = [] as any;
      contactData.value.forEach(function (value) {
        contactsModel.push(value.id);
      });
      const officesModel = [] as any;
      officesData.value.forEach(function (value) {
        officesModel.push(value.id);
      });
      const sectorsModel = [] as any;
      sectorData.value.forEach(function (value) {
        sectorsModel.push(value.id);
      });
      const syncModel = [] as any;
      sync.value.forEach(function (value) {
        syncModel.push(value);
      });
      const economicData = [] as any;
      eDataTable.value.forEach(function (value) {
        economicData.push({
          type: value.type,
          office_id: value.office_id,
          payment_days: value.payment_days,
          applies: value.applies,
          payment_type_detail_id: value.payment_type_detail_id,
        });
      });
      const data = {
        name: entityData.value.name,
        legal_name: entityData.value.legal_name,
        identification_type_id: entityData.value.identification_type_id,
        identification: entityData.value.identification,
        email: entityData.value.email,
        entity_types: entitiesModel,
        sectors: sectorsModel,
        offices: officesModel,
        contacts: contactsModel,
        addresses: addressData.value,
        economic_data: economicData,
        synchronize_flags: sync.value,
      };
      ApiService.post("/api/entities/update/" + route.params.id, data).then(
        function () {
          //
        }
      );
    };

    const permissionsTemp = localStorage.getItem("permissions") as any;
    const permissions = {
      value: JSON.parse(permissionsTemp),
    };

    const canUpdate = computed(() => {
      return (
          permissions.value.findIndex((x) => x.name === "entities: update") !==
          -1
      );
    });

    return {
      saveAll,
      entities_list,
      communicationsTable,
      entities_type_list,
      contactDetail,
      addAction,
      contactData,
      entity_address_type_warning_flag,
      addContacts,
      findContacts,
      createContact,
      createCommunication,
      getCommunication,
      saveContact,
      removeContact,
      removeCommunication,
      saveEntity,
      saveContactCommunication,
      canUpdate,
    };
  },
});
